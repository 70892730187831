import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import type { SxProps } from '@mui/system';

import {
  Stack,
  useDeviceType,
  Container,
  Box,
  Link,
  Skeleton,
  useUrl,
  useUserAgentDevice,
} from '@packages/shared';
import { useConfig } from '@packages/utilities';
import type { GenericTrackingEvent } from '@packages/tracking';
import { useTracking } from '@packages/tracking';

import type { NavItem as NavItemProps, NavigationData } from '../types';
import { findNode } from './findNode';
import { NavigationOverlay } from './DesktopNavigationOverlay';
import { useNavigationData } from '../useNavigationData';

const borderHighlightStyles: SxProps = {
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: 0,
    height: '0.25rem',
    width: '100%',
    borderRadius: '0.25rem 0.25rem 0 0',
    backgroundColor: 'primary.main',
    zIndex: 'appBar',
  },
};
const backgroundHighlightStyles: SxProps = {
  color: 'text.dark',
  backgroundColor: 'grey.light',
};

const NavItem = ({
  highlight,
  displayName,
  highlightColor,
  uri,
  fontSize,
  onMouseEnter,
  onClick,
  fontFamily,
  isSecondLevel = false,
  isActive,
}: NavItemProps & {
  highlight: 'border' | 'background';
  isSecondLevel?: boolean;
  fontFamily?: string;
  fontSize?: string;
  onMouseEnter: () => void;
  onClick: (event: MouseEvent) => void;
  isActive?: boolean;
}) => (
  <Link
    onMouseEnter={onMouseEnter}
    onClick={onClick}
    sx={[
      {
        outline: 'none',
        color: 'inherit',
        textDecoration: 'none',
        p: 1,
        position: 'relative',
        fontFamily,
        '&, &:link, &:hover, &:visited': {
          color: highlightColor,
        },
      },
      // eslint-disable-next-line no-nested-ternary
      isActive
        ? highlight === 'border'
          ? borderHighlightStyles
          : backgroundHighlightStyles
        : null,
      isSecondLevel
        ? {
            fontSize: '0.875rem',
            py: 1.5,
            px: 1,
          }
        : {
            fontSize: fontSize || [null, null, null, '1rem', '1.125rem'],
          },
      highlight === 'border'
        ? {
            '&:hover': borderHighlightStyles,
          }
        : {
            '&:hover': backgroundHighlightStyles,
          },
    ]}
    href={uri || ''}
  >
    {displayName}
  </Link>
);

interface DesktopNavigationContentProps {
  data: NavigationData;
  onActivateItem: () => void;
  isSecondLevel?: boolean;
  rootCategoryId?: string;
  isBot?: boolean;
}

// TODO: make non-recursive, supports only 2 levels anyways, and both are fairly different
const DesktopNavigationContent = ({
  data = [],
  isBot,
  rootCategoryId,
  isSecondLevel,
  onActivateItem,
}: DesktopNavigationContentProps) => {
  const defaultActiveItem = isBot
    ? data.find((item: NavItemProps) => item.rootCategoryId === rootCategoryId)
    : undefined;
  const { isTouch } = useDeviceType();
  const userAgentDevice = useUserAgentDevice();
  const [activeItem, setActiveItem] = useState<NavItemProps | undefined>(defaultActiveItem);
  const [activePath, setActivePath] = useState<string>('');

  const dispatchGTMEvent = useTracking();

  const {
    navigation: { firstLevel, secondLevel, firstLevelFont, firstLevelFontSize },
  } = useConfig();

  const themeProps = isSecondLevel && secondLevel ? secondLevel : firstLevel;

  const url = useUrl();

  // close navigation on location change
  useEffect(() => {
    if (!isBot) {
      setActiveItem(undefined);
    }
  }, [isBot, url]);

  // need to search the new active item, if we load the full navigation
  useEffect(() => {
    if (activeItem?.categoryId) {
      // find new active in navigation
      const newItem = data.find((currentNode) =>
        findNode(activeItem.categoryId as string, currentNode),
      );
      if (newItem) setActiveItem(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const activateItem = (item: any) => {
    setActiveItem(item);
    onActivateItem?.();
  };

  return (
    <Box
      component="nav"
      onMouseLeave={() => setActiveItem(undefined)}
      sx={{
        borderBottom: isSecondLevel ? 'none' : '0.25rem solid',
        borderBottomColor:
          secondLevel && (isSecondLevel || activeItem) ? 'transparent' : themeProps.border,
        color: themeProps.color,
        position: isSecondLevel ? 'absolute' : 'relative',
        left: 0,
        right: 0,
        top: 0,
        backgroundColor: themeProps.backgroundColor,
        zIndex: isSecondLevel ? 'appBar' : (theme) => theme.zIndex.appBar + 1, // +1 to be above the AppBar (and the filter popover)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: `-0.25rem`,
          height: '0.25rem',
          left: 'var(--background-bleed)',
          right: 'var(--background-bleed)',
          backgroundColor: themeProps.border,
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      />
      <Container>
        <Stack
          spacing={0}
          direction="row"
          justifyContent={isSecondLevel ? 'flex-start' : 'space-between'}
          alignItems="stretch"
          mx={-1}
          sx={{
            overflowX: 'auto',
            // NOTE: scrollbars are TEMPORARILY (!!) enabled, due to nav tree restructuring by Baur, which is not yet finished but is critical for the go-live with Next.js
            // TODO: implement a proper solution after discussions, one related issue is SEARCH-2321
            // '::-webkit-scrollbar': {
            //   /* Chrome hide scrollbar but keep functionality */
            //   display: 'none',
            // },
            // msOverflowStyle: 'none' /* IE and Edge */,
            // scrollbarWidth: 'none' /* Firefox */,
          }}
        >
          {data.map((navItem) => (
            <NavItem
              highlight={secondLevel ? 'border' : 'background'}
              isSecondLevel={isSecondLevel}
              onMouseEnter={() => {
                activateItem(navItem);
              }}
              onClick={(event: MouseEvent) => {
                // is only executed on touch-enabled devices that are not desktops to stop navigating to navitem.uri.
                if (isTouch && userAgentDevice !== 'desktop') {
                  event.preventDefault();
                  event.stopPropagation();
                  if (activePath !== navItem.uri) {
                    setActivePath(navItem?.uri || '');
                    setActiveItem(navItem);
                  } else {
                    setActivePath('');
                    setActiveItem(undefined);
                  }
                }
                const pathSegments = navItem?.uri?.split('/').filter((v) => v);
                dispatchGTMEvent<GenericTrackingEvent>({
                  event: 'Layer_Navi',
                  eventValue: pathSegments,
                });
                dispatchGTMEvent<GenericTrackingEvent>({
                  event: 'suche-event',
                  eventValue: `navi=${encodeURIComponent(navItem.displayName ?? '')}`,
                });
              }}
              key={navItem.uri}
              fontFamily={firstLevelFont}
              fontSize={firstLevelFontSize}
              isActive={navItem.categoryId === activeItem?.categoryId}
              {...navItem}
            />
          ))}
        </Stack>
      </Container>
      {!!activeItem?.children?.length &&
        (isSecondLevel || !secondLevel ? (
          <NavigationOverlay data={activeItem.children} />
        ) : (
          <DesktopNavigationContent
            onActivateItem={onActivateItem}
            data={activeItem.children}
            isBot={isBot}
            isSecondLevel
          />
        ))}
    </Box>
  );
};

/**
 * Desktop navigation wrapper component which is a 'render-as-you-fetch' component
 * Because the DesktopNavigationContent component is recursive, we need this wrapper component which is responsible for the data
 */
export const DesktopNavigation = () => {
  const { data: navigationData, loadFullData } = useNavigationData();

  return <DesktopNavigationContent data={navigationData} onActivateItem={loadFullData} />;
};

/**
 * Loading fallback for desktop navigation row
 * Actually used only when component is whithout initial data and is purely CSR.
 * In future the fallback will be used when this component will become a server component
 */
export const DesktopNavigationLoadingFallback = () => (
  <Box
    sx={{
      inset: '100% 0 auto 0',
      borderBottom: '4px solid',
      borderBottomColor: 'grey.light',
    }}
  >
    <Container>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          py: 1,
          height: 36,
          gap: 2,
          span: { flexGrow: 1, height: 20 },
        }}
      >
        {[...Array(8)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton variant="rectangular" key={i} />
        ))}
      </Stack>
    </Container>
  </Box>
);

// TODO: add desktop navigation error fallback component
